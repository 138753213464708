import { Button } from "@3beehivetech/components";
import Link from "../../Link/Link";
import React, { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { 
  StyledWhoWeAre,
  StyledSubtitle,
  StyledTitle,
  StyledWhoWeAreButtons,
} from "../style";

const WhoWeAre = () => {
  const { pageLabels, i18nLink } = useContext(DataContext);
  const labels = pageLabels.whoWeAre;

  return (
    <StyledWhoWeAre>
      <StyledTitle>{labels.title}</StyledTitle>
      <StyledSubtitle dangerouslySetInnerHTML={{ __html: labels.subtitle }}/>

      <StyledWhoWeAreButtons>
        <Link to={i18nLink("/adotta-alveare")}>
          <Button style="text-transform: uppercase;">
            {labels.buttonAdopt}
          </Button>
        </Link>

        <Link to={i18nLink("/business")}>
          <Button style="text-transform: uppercase;">
            {labels.buttonBusiness}
          </Button>
        </Link>
      </StyledWhoWeAreButtons>
    </StyledWhoWeAre>
  );
};

export default WhoWeAre;
